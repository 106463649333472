import { useEffect, useState } from "react";
import _ from 'lodash';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import Model from "../../libs/ModelClass";
import config from "../../config";
import { useAuth } from "../../modules/user/AuthContext";


const maxBags = config?.modules?.cart?.bagsMaxQty;
const maxRepetitiveDays = config?.modules?.cart?.maxRepetitiveDays;
const maxToleranceForOldPlanning = config?.modules?.cart?.maxToleranceForOldPlanning;

const CartModel = Model.extend(config.modules.cart.cartsEntitySlug);
const ItemModel = Model.extend(config.modules.cart.cartItemEntitySlug);
const CategoryModel = Model.extend(config.modules.cart.cartItemCategoriesEntitySlug);
const TypeModel = Model.extend(config.modules.cart.cartItemTypesEntitySlug);
const PlanningItemsOptionsModel = Model.extend(config.modules.cart.planningItemsOptionsEntitySlug);

export const getPrice = (bag, item) => {
  if (bag?.price || (bag?.specialPricesById && bag?.specialPricesById[item.id])) {
    return bag?.price || (bag?.specialPricesById && bag?.specialPricesById[item.id]);
  }
  return item.data.price;
};

export const useCartData = () => {
  const { user } = useAuth();
  // Tener lista de menu de platos con isActive:true !delete
  // Tener lista de categorias 
  // Tener lista de tipos
  const [cartDoc, setCartDoc] = useState();
  const [items, setItems] = useState();
  const [itemsTypes, setItemsTypes] = useState();
  const [itemsCategories, setItemsCategories] = useState();
  const [planning, setPlanning] = useState();
  const [bags, setBags] = useState([]);
  const [totalPriceItems, setTotalPriceItems] = useState(0);
  const [selectedInstitution, setSelectedInstitution] = useState();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   processPlanningData();
  // }, []);

  useEffect(() => {
    selectedInstitution && fetchPlanningItemsOptionsList();
  }, [selectedInstitution]);

  useEffect(() => {
    calculateTotalItems(); // Calculate the initial total
  }, [bags]);

  const processPlanningData = async (force, institutionIdForFilter, mode = 'cart') => { // mode cart, planning
    setLoading(true);
    // if ( localStorage.getItem('cartId') ) {
    //   let cartDoc = await CartModel.findById( localStorage.getItem('cartId') );
    //   if (cartDoc?.id && !cartDoc?.data?.itemsInBags?.length) {
    //     setCartDoc(cartDoc);
    //   }
    // }
    if (!items || force) {
      let docs = await ItemModel.getAll();
      docs = docs.filter((doc) => {
        return doc.data.available && !doc.data.deleted;
      });
      setItems(docs);
    }
    if (!itemsTypes || force) {
      let docs = await TypeModel.getAll();
      docs = docs.filter((doc) => {
        return !doc.data.deleted;
      });
      let mainItemType;
      _.remove(docs, (doc) => {
        if (doc.id === config.modules.cart.mainItemTypeId) {
          mainItemType = doc;
          return true;
        }
      });
      if (mainItemType) {
        docs.unshift(mainItemType);
      }
      setItemsTypes(docs);
    }
    if (!itemsCategories || force) {
      let docs = await CategoryModel.getAll();
      docs = docs.filter((doc) => {
        return !doc.data.deleted;
      });
      setItemsCategories(docs);
    }
    if (!bags.length || force || mode === 'planning') {
      await fetchPlanningItemsOptionsList(institutionIdForFilter, mode);
    }
    setLoading(false);
  };

  const fetchPlanningItemsOptionsList = async (institutionIdForFilter, mode) => {
    let filterQuery = {
      startDate: {
        gte: dayjs().subtract(maxToleranceForOldPlanning, 'days').utc().startOf('day').toISOString()
      }
    };
    if (institutionIdForFilter || selectedInstitution) {
      filterQuery['institutionId'] = institutionIdForFilter || selectedInstitution.id;
    }
    let records = await PlanningItemsOptionsModel.filterByAttributes(filterQuery);
    records = records.filter((doc) => {
      return !doc.data.deleted;
    });
    if (mode === 'cart') {
      records = records.filter((doc) => {
        if (institutionIdForFilter) {
          return doc.data.institutionId === institutionIdForFilter;
        } else {
          return !doc.data.institutionId;
        }
      });
    }
    let today = dayjs().startOf('day').utc();
    let sortedRecords = _.sortBy(records, (doc) => doc.data.startDate);
    let futureRecords = [];
    let pastRecords = [];
    
    sortedRecords.forEach((record) => {
      const startDate = dayjs(record.data.startDate).utc();
      if (startDate.isAfter(today)) {
        futureRecords.push(record);
      } else if (startDate.isBefore(today)) {
        pastRecords.push(record);
      }
    });
    pastRecords = pastRecords.reverse();
    const currentActive = extractFirstPlanningByInstitution(pastRecords);
    const planning = {
      active: currentActive.length ? currentActive : [],
      future: futureRecords,
      past: pastRecords
    };

    setPlanning(planning);
    setBags( calcBagsPlannedByTimeRange(planning) );
  };

  const extractFirstPlanningByInstitution = (docsList) => {
    // Object to keep track of found institutions
    const foundInstitutions = {};
    // Array to store the first objects of each institution
    const firstObjectsPerInstitution = [];
    // Iterate over the original array
    for (const doc of docsList) {
      // Check if we've already found an doc for this institution
      if (!foundInstitutions[doc.data.institutionId]) {
        // If not found, add it to the array of first docs
        firstObjectsPerInstitution.push(doc);
        // Mark this institution as found
        foundInstitutions[doc.data.institutionId] = true;
      }
    }
    // Remove the first docs of each institution from the original array
    for (const doc of firstObjectsPerInstitution) {
      const index = docsList.findIndex(item => item === doc);
      if (index !== -1) {
        docsList.splice(index, 1);
      }
    }
    return firstObjectsPerInstitution;
  };

  const fetchOldPlanningDocs = async () => {
    let filterQuery = {
      startDate: {
        lte: dayjs().utc().startOf('day').toISOString()
      }
    };
    if (selectedInstitution) {
      filterQuery['institutionId'] = selectedInstitution.id;
    }
    let records = await PlanningItemsOptionsModel.filterByAttributes(filterQuery);
    records = records.filter((doc) => {
      return !doc.data.deleted;
    });
    let today = new Date();
    const sortedRecords = _.sortBy(records, (doc) => doc.data.startDate);
    const pastRecords = [];
    sortedRecords.forEach((record) => {
      const startDate = dayjs(record.data.startDate).utc();
      if (startDate.isBefore(today)) {
        pastRecords.push(record);
      }
    });
    extractFirstPlanningByInstitution(pastRecords);
    setPlanning({
      ...planning,
      past: pastRecords.reverse()
    });
  };

  const calcBagsPlannedByTimeRange = (planning) => {
    let maxHourToShow = 7*60; // en minutos
    const ranges = [ ...planning.active, ...planning.future ];
    let bags = [];
    const todayLocalSting = dayjs.tz().format().slice(0, -6);
    const today = dayjs.utc( todayLocalSting );
    // console.log('\n\n\nplanning', planning);
    // console.log('today', today.format(), today.toISOString());
    _.forEach(ranges, (range, i) => {
      if (range.data.isLimit) {
        return false;
      }
      let startDate = dayjs.utc(range.data.startDate).startOf('day');
      // console.log('startDate', startDate.format(), startDate.toISOString(), range.data.startDate);
      let maxDate;
      let nextStartDate = ranges[i + 1];
      if (nextStartDate) {
        maxDate = dayjs(nextStartDate.data.startDate).utc();
      }
      else {
        maxDate = dayjs(range.data.startDate).utc().add(maxRepetitiveDays, 'day');
      }
      let totalDays = maxDate?.diff( startDate, 'day');
      // console.log('in ranges 1', startDate.toISOString(), maxDate.toISOString(), totalDays);
      _.range(totalDays).forEach((dayId) => {
        let date = startDate.add(dayId, 'day');
        let dayOfWeek = date.day();
        date = date.add(maxHourToShow, 'minutes');
        // console.log('in range 2', date.format(), date.utc().format(), dayOfWeek, date.isAfter(today));
        if (
          // only week days
          !(dayOfWeek === 0 || dayOfWeek === 6)
          // only after today
          && (date.isAfter(today))
        ) {
          bags.push({
            date,
            plan: range.id,
            itemsAvailable: range.data.itemsBag,
            itemsInBag: [],
            specialPricesById: range.data.specialPricesById,
            planning: range
          });
        }
      });
    });
    if (maxBags && bags.length > maxBags) {
      bags = bags.slice(0, maxBags);
    }
    // console.log('bags', bags)
    return bags;
  };

  const calculateTotalItems = (bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    let total = 0;
    bagsToUse.forEach((_, index) => {
      const bagTotal = getBagTotal(index, bagsToUse);
      total += bagTotal;
    });
    setTotalPriceItems(total);
    return total;
  };

  const getBagById = (bagId, bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    return bagsToUse[bagId];
  };

  const getBagTotal = (bagId, bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    const bag = getBagById(bagId, bagsToUse);
    
    if (bag) {
      // Calculate the total quantity of items in the bag
      const total = bag.itemsInBag.reduce((acc, itemInBag) => {
        const itemDoc = items.find((item) => item.id === itemInBag.id);
        return acc + itemInBag.qty * (getPrice(bag, itemDoc) || 0);
      }, 0);
      return total;
    }
  
    return 0;
  };  

  const getItemsOfBag = (bagId, bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    const bag = getBagById(bagId, bagsToUse);
  
    if (bag) {
      // Find the item with matching id
      const foundItems = bag.itemsInBag.map((itemInBag) => {
        const result = {
          ...itemInBag,
        };
        result.itemDoc = items.find((item) => item.id === itemInBag.id);
        if (result.itemDoc.data.withExtra) {
          result.itemExtraDoc = items.find((item) => item.id === itemInBag.withExtra);
        }
        return result;
      });
      
      return foundItems; // Return the found itemInBag object
    }
  
    return null; // Bag with the specified bagId not found
  };  

  const isItemInBag = (bagId, itemId, withExtraItemId) => {
    const bag = getBagById(bagId);
  
    if (bag) {
      // Find the item with matching id and, if provided, withExtraItemId in the bag
      const foundItem = bag.itemsInBag.find((itemInBag) => {
        if (withExtraItemId) {
          return itemInBag.id === itemId && itemInBag.withExtra === withExtraItemId;
        } else {
          return itemInBag.id === itemId && !itemInBag.withExtra;
        }
      });
      if (foundItem) {
        // Attach doc of main item
        const itemDoc = items.find((item) => item.id === foundItem.id);
        return { ...foundItem, itemDoc }; // Return the found itemInBag object
      }
      return foundItem; // Return the found itemInBag object
    }
  
    return null; // Bag with the specified bagId not found
  };  

  /**
   * Notifies the user that the bag has max length
   * return true if the bag has been reached max length
   * @returns boolean
   */
  const checkMaxItemsPerBag = (bag) => {
    if (selectedInstitution?.data?.maxItemsPerBag > 0) {
      let totalQty = bag.itemsInBag.reduce((totalQty, itemInBag) => {
        return totalQty + itemInBag.qty;
      }, 0);
      let reached = false;
      if (selectedInstitution.data.maxItemsPerBag === 1 && totalQty > 1) {
        toast.error('Puede seleccionar sólo 1 plato por día');
        reached = true;
      }
      else if (totalQty > 1 && totalQty > selectedInstitution.data.maxItemsPerBag) {
        toast.error(`Puede seleccionar sólo hasta ${selectedInstitution.data.maxItemsPerBag} platos por día`);
        reached = true;
      }
      return reached;
    }
  };

  const setItemToBag = (bagId, itemId, qty, withExtraItemId) => {
    // Find the bag by its ID
    const bagIndex = bags.findIndex((bag, index) => index === bagId);
    
    if (bagIndex === -1) {
      console.error(`Bag with ID ${bagId} not found.`);
      return;
    }
  
    // Deep clone the bags array to avoid directly modifying the state
    const updatedBags = _.cloneDeep(bags);

    // Check if the item is already in the bag
    const bag = updatedBags[bagIndex];
    const existingItem = bag.itemsInBag.find((itemInBag) => {
      // Check if the item and withExtraItemId match
      if (withExtraItemId) {
        return itemInBag.id === itemId && itemInBag.withExtra === withExtraItemId;
      } else {
        return itemInBag.id === itemId && !itemInBag.withExtra;
      }
    });    
  
    if (qty === 0) {
      // Remove the item from the bag if qty is set to 0
      if (existingItem) {
        // If withExtraItemId is provided, only remove items with the same id and withExtraItemId combination
        const updatedItemsInBag = bag.itemsInBag.filter((itemInBag) => {
          if (withExtraItemId) {
            return !(itemInBag.id === itemId && itemInBag.withExtra === withExtraItemId);
          } else {
            return itemInBag.id !== itemId;
          }
        });
        if (checkMaxItemsPerBag(bag)) { return; }
        bag.itemsInBag = updatedItemsInBag;
      }
    }
    // If qty is not 0
    else {
      // add the item quantity
      if (!existingItem) {
        bag.itemsInBag.push({
          id: itemId,
          qty: qty,
          withExtra: withExtraItemId, // Set withExtraItemId if provided
        });
        if (checkMaxItemsPerBag(bag)) { return; }
      } 
      // update the item quantity
      else {
        const oldItemsInBag = bag.itemsInBag;
        const updatedItemsInBag = bag.itemsInBag.map((itemInBag) => {
          if (itemInBag.id === itemId) {
            if (withExtraItemId) {
              // Check if the item in the bag already has withExtraItemId
              if (itemInBag.withExtra === withExtraItemId) {
                // Update the quantity only if the withExtraItemId matches
                return {
                  ...itemInBag,
                  qty: qty,
                };
              } else {
                // The item in the bag has a different withExtraItemId; leave it unchanged
                return itemInBag;
              }
            } else if (!itemInBag.withExtra) {
              // Update the quantity only if itemInBag.withExtra is not assigned
              return {
                ...itemInBag,
                qty: qty,
              };
            }
          }
          return itemInBag;
        });
        bag.itemsInBag = updatedItemsInBag;
        if (checkMaxItemsPerBag(bag)) {
          bag.itemsInBag = oldItemsInBag;
          return;
        }
      }      
    }
    // Update the bags state with the updated bag
    updatedBags[bagIndex] = bag;
    setBags(updatedBags);
    return updatedBags;
    // return saveCurrentCart(updatedBags);
  };
  
  const saveCurrentCart = async (bagsToUse) => {
    if (cartDoc) {
      bagsToUse = bagsToUse || bags;
      cartDoc.data.itemsInBags = sanitizeItemsBags(bagsToUse);
      cartDoc.data.total = calculateTotalItems(bagsToUse);
      cartDoc.data.daysSelected = calculateDaysSelected(bagsToUse);
      await cartDoc.save();
    }
    else {
      await saveNewCart();
    }
  };

  const saveNewCart = async (bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    const cartData = {
      userId: user.userDoc.id,
      institutionId: user.userDoc.data.institutionId,
      itemsInBags: sanitizeItemsBags(bagsToUse),
      total: calculateTotalItems(bagsToUse),
      daysSelected: calculateDaysSelected(bagsToUse)
    };
    const newCart = await CartModel.create(cartData);
    setCartDoc(newCart);
    // localStorage.setItem('cartId', newCart.id);
    return newCart;
  };

  const calculateDaysSelected = (bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    const daysSelected = [];
    // sanitize for saving
    bagsToUse.forEach((bag) => {
      if (bag.itemsInBag.length) {
        daysSelected.push( dayjs(bag.date).utc().format('YYYY-MM-DD') );
      }
    });
    return daysSelected;
  };

  const sanitizeItemsBags = (bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    const itemsInBags = [];
    // sanitize for saving
    bagsToUse.forEach((bag, bagId) => {
      if (bag.itemsInBag.length) {
        const bagItems = [];
        bag.itemsInBag.forEach(({ id, qty, withExtra }) => {
          const itemDoc = items.find((item) => item.id === id);
          const price = getPrice(bag, itemDoc);
          if (withExtra) {
            bagItems.push({ id, qty, price, withExtra });
          } else {
            bagItems.push({ id, qty, price });
          }
        });
        itemsInBags.push({
          bagItems: bagItems,
          date: dayjs(bag.date).utc().format('YYYY-MM-DD'),
          total: getBagTotal(bagId, bagsToUse)
        });
      }
    });
    return itemsInBags;
  };
  
  const closeCart = () => {
    localStorage.removeItem('cartId');
    setCartDoc();
    setBags([]);
  };

  return { 
    CartModel, ItemModel, CategoryModel, TypeModel, 
    items, itemsTypes, itemsCategories,
    planning, processPlanningData, fetchPlanningItemsOptionsList, fetchOldPlanningDocs, PlanningItemsOptionsModel, 
    selectedInstitution, setSelectedInstitution,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    totalPriceItems, getPrice,
    loading
  };
}