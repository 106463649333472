import { RouteAddCart } from "./RouteAddCart";
import entities from './entities.json';
import _ from 'lodash';
// import { ReadOnlyItemsInBags } from "./ReadOnlyItemsInBags";
import { RouteCartHistory } from "./RouteCartHistory";
import { RouteCartMaterialsByDay } from "./RouteCartMaterialsByDay";
import { RouteDeliveryHistory } from "../cartDelivery/RouteDeliveryHistory";
import { RouteDeliveryPlanning } from "../cartDelivery/RouteDeliveryPlanning";
import { RouteDeliveryPendingOrders } from "../cartDelivery/RouteDeliveryPendingOrders";
import { RouteDeliveryShowOrder } from "../cartDelivery/RouteDeliveryShowOrder";
import BtnLoading from "../../components/ui/BtnLoading";
import Model from "../../libs/ModelClass";
import dayjs from "dayjs";


let { carts } = entities;

/*
  carts: [{
    userId,
    institutionId,
    total,
    itemsInBags: [{
      id,        // id of the item
      withExtra, // sub item of item in cart, example: toppings
      qty,
      price
    }]
  }],
*/

const getCrudSettingsCarts = () => {
  return {
    showBtnAdd: false
  };
}

const cancelAllByDayAndInstitution = async () => {
  const ExtendedModel = Model.extend('carts');
  let filteredDocs = await ExtendedModel.filterByAttributes({
    institutionId: 'PVuh4arU1ardcvCdEsBW',
    daysSelected: { 'in-array': ['2024-03-07'] }
  });
  for (const cartDoc of filteredDocs) {
    for (const itemsInBags of cartDoc.data.itemsInBags) {
      if (itemsInBags.date === '2024-03-07') {
        for (const itemInBag of itemsInBags.bagItems) {
          console.log(itemInBag)
          itemInBag.deleted = true; 
        }
        itemsInBags.deleted = true;
        itemsInBags.total = 0;
      }
    }
    await cartDoc.save();
    console.log('Updated cart', cartDoc.id)
  }
};

const mergeDuplicatedUserProfiles = async () => {
  const ExtendedModel = Model.extend('usersProfiles');
  let filteredDocs = await ExtendedModel.getAll();
  // filteredDocs = filteredDocs.filter((doc) => {
  //   return !doc.data.deleted;
  // });
  let groupByEmail = _.groupBy(filteredDocs, (user) => user.data.email);
  let filteredByEmail = {};
  _.forEach(groupByEmail, (profilesDocs, email) => {
    if (profilesDocs.length > 1) {
      filteredByEmail[email] = profilesDocs;
    }
  });
  await conciliateUserProfiles(filteredByEmail["rodrigo182benitez@gmail.com"], 0)

  console.log('Grouped and filtered by email', filteredByEmail)
};

const listCartOfUser = async () => {
  const UserModel = Model.extend('usersProfiles');
  let userDocs = await UserModel.filterByAttributes({
    email: 'gomescarmelo431@gmail.com'
  });
  console.log('userDocs', userDocs[0])
  if (userDocs?.length) {
    const ExtendedModel = Model.extend('carts');
    let filteredDocs = await ExtendedModel.filterByAttributes({
      userId: userDocs[0].id
    });
    console.log('filteredDocs', filteredDocs)
  }
};

async function conciliateUserProfiles (docs, correctIndex) {
  if (!docs) return;
  const userIdCorrect = docs[correctIndex].id;
  const CartsModel = Model.extend('carts');
  const CredentialsModel = Model.extend('credentials');

  for (const profileDoc of docs) {
    console.log('profileDoc', profileDoc)
    if (profileDoc.id !== userIdCorrect) {
      // patch carts
      const cartsToUpdate = await CartsModel.where('userId', '==', profileDoc.id);
      if (cartsToUpdate.length > 0) {
        console.log('cartsToUpdate', profileDoc.id, cartsToUpdate);
        for (const cartDoc of cartsToUpdate) {
          cartDoc.data.userId = userIdCorrect;
          await cartDoc.save();
          console.log('cart updated', cartDoc.id);
        }
      } else {
        console.log('nothing to update', profileDoc.id); 
      }
      // remove userProfile
      const credentialToRemove = await CredentialsModel.whereOne('profile', '==', profileDoc.id);
      if (credentialToRemove) {
        console.log('credentialToRemove', credentialToRemove)
        await credentialToRemove.deleteFromDisk();
      }
      await profileDoc.deleteFromDisk();
      console.log('profileDoc deleted', profileDoc);
    } // if
  } // for
}

const fixDatesOfBags = async () => {
  const ids = [
    '9hN5jNvrkl275S9Yevb9',
    'WJXFgzvWkJJopf4yLXW4',
    '1bfC2LqntbgdJEaPcrXB',
    '2JRJFYb1ZRtj5SGjSiUB',
    'gWhYuNduWGtssm1AoMJa',
    'ztGyRKgMu1kSFaYEKMsx'
  ];
  const CartsModel = Model.extend('carts');
  for (const cartId of ids) {
    const cart = await CartsModel.findById(cartId);
    console.log('\n\n\nbefore', cart);
    let daysSelected = [];
    for (const bagId in cart.data.itemsInBags) {
      let newDate = dayjs(cart.data.itemsInBags[bagId].date).add(1, 'day').format('YYYY-MM-DD');
      console.log(cart.data.itemsInBags[bagId].date, '->', newDate);
      daysSelected.push(newDate);
      cart.data.itemsInBags[bagId].date = newDate;
    }
    cart.data.daysSelected = daysSelected;
    console.log('after', cart);
    await cart.save();
  }
};

const usersList = async () => {
  const UserModel = Model.extend('usersProfiles');
  let userDocs = await UserModel.getAll();
  // filtra los userDoc.data.ci que empiecen por "3600"
  userDocs = userDocs.filter((user) => {
    return _.startsWith(user.data.ci, '3600');
  });
  console.log('userDocs', userDocs)
};


const AdminConfigAfterLinksList = ({ isAllowed }) => (<>
  <div className="mt-10">
    {/* <BtnLoading 
      label="Anular todo por día por Institución"
      onClickAsync={cancelAllByDayAndInstitution}
      className="block mx-auto font-semibold !items-start !justify-start px-3 py-2 w-[220px] !bg-brand-red !text-white shadow-md !no-underline hover:underline hover:scale-105 transition duration-400 ease-in-out"
      colorClass="text-gray-400 hover:text-gray-700"
    /> */}

    {/* <BtnLoading 
      label="listCartOfUser"
      onClickAsync={listCartOfUser}
      className="block mx-auto font-semibold !items-start !justify-start px-3 py-2 w-[220px] !bg-brand-red !text-white shadow-md !no-underline hover:underline hover:scale-105 transition duration-400 ease-in-out"
      colorClass="text-gray-400 hover:text-gray-700"
    /> */}

    {/* <BtnLoading 
      label="users lists"
      onClickAsync={usersList}
      className="block mx-auto font-semibold !items-start !justify-start px-3 py-2 w-[220px] !bg-brand-red !text-white shadow-md !no-underline hover:underline hover:scale-105 transition duration-400 ease-in-out"
      colorClass="text-gray-400 hover:text-gray-700"
    /> */}

    {/* <BtnLoading 
      label="Corregir fechas"
      onClickAsync={fixDatesOfBags}
      className="block mx-auto font-semibold !items-start !justify-start px-3 py-2 w-[220px] !bg-brand-red !text-white shadow-md !no-underline hover:underline hover:scale-105 transition duration-400 ease-in-out"
      colorClass="text-gray-400 hover:text-gray-700"
    /> */}

  </div>
</>);

export default function ({ setPermissions }) {
  return {
    name: 'Cart',
    slug: 'cart',

    permissions: [
      setPermissions({ slug: 'carts', label: 'Carritos', type: 'coded', actionsToAdd: ['history', 'materialsByDay', 'cancelOldItem'] }),
      setPermissions({ slug: 'delivery', label: 'Entregas', type: 'coded', actions: ['history', 'read', 'planning', 'pendingOrders'] }),
    ],

    entities: {
      carts
    },

    routesAdmin: {
      'cart': {
        'add': {
          permission: { resource: 'carts', action: 'create' },
          Content: RouteAddCart
        },
        'history': {
          permission: { resource: 'carts', action: 'history' },
          Content: RouteCartHistory
        },
        'materialsByDay': {
          permission: { resource: 'carts', action: 'materialsByDay' },
          Content: RouteCartMaterialsByDay
        }
      },
      'delivery': {
        'history': {
          permission: { resource: 'delivery', action: 'history' },
          Content: RouteDeliveryHistory
        },
        'showOrder': {
          permission: { resource: 'delivery', action: 'read' },
          Content: RouteDeliveryShowOrder
        },
        'planning': {
          permission: { resource: 'delivery', action: 'planning' },
          Content: RouteDeliveryPlanning
        },
        'pendingOrders': {
          permission: { resource: 'delivery', action: 'pendingOrders' },
          Content: RouteDeliveryPendingOrders
        }
      }
    },


    components: {
      'main': {
        AdminConfigAfterLinksList: AdminConfigAfterLinksList
      },
      'carts': {
        crud: {
          settings: getCrudSettingsCarts
        },
        codedDataTypes: {
          // 'itemsInBags': {
          //   RenderForm: ReadOnlyItemsInBags,
          //   RenderShow: ReadOnlyItemsInBags
          // },
        }
      },
    }
  };
};